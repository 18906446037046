import React from 'react';
import { useTheme } from '@mui/material/styles';

const DirectLinkMenu = ({ title, url, colorInvert }) => {
  const theme = useTheme();

  // You can customize this part to style the direct link as you wish
  const linkStyles = {
    textDecoration: 'none',
    color: colorInvert ? theme.palette.common.white : theme.palette.text.primary,
    // Add other styles if needed
  };

  return (
    <a href={url} style={linkStyles}>
      {title}
    </a>
  );
};

export default DirectLinkMenu;