import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import airbnb from '../../../../layouts/Main/components/images/airbnb.svg';
import amazon from '../../../../layouts/Main/components/images/amazon.svg';
import fitbit from '../../../../layouts/Main/components/images/fitbit.svg';
import netflix from '../../../../layouts/Main/components/images/netflix.svg';
import google from '../../../../layouts/Main/components/images/google.svg';
import paypal from '../../../../layouts/Main/components/images/paypal.svg';


const Vendors = () => {
  const theme = useTheme();
  return (
    <Box>
      <Box display="flex" flexWrap="wrap" justifyContent={'center'}>
        {[
          airbnb,
          amazon,
          fitbit,
          netflix,
          google,
          paypal,
        ].map((item, i) => (
          <Box maxWidth={90} marginTop={2} marginRight={4} key={i}>
            <Box
              component="img"
              height={1}
              width={1}
              src={item}
              alt="..."
              sx={{
                filter:
                  theme.palette.mode === 'dark'
                    ? 'brightness(0) invert(0.7)'
                    : 'none',
              }}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Vendors;
