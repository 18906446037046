/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import { colors } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import cloudserver from '../../../../layouts/Main/components/images/server.jpg';

const CaseStudy2 = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box
      padding={{ xs: 2, sm: 4 }}
      borderRadius={2}
      bgcolor={
        theme.palette.mode === 'light' ? colors.red[50] : colors.red[900]
      }
      data-aos={'fade-up'}
    >
      <Grid
        container
        spacing={isMd ? 4 : 2}
        flexDirection={{ xs: 'column-reverse', md: 'row' }}
      >
        <Grid item xs={12} md={4}>
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={{ xs: 'flex-start', md: 'space-between' }}
            height={1}
          >
            <Box>
              <Typography variant={'h4'} fontWeight={700} gutterBottom>
                Cloud Services
              </Typography>
              <Typography
                color={
                  theme.palette.mode === 'light'
                    ? 'text.secondary'
                    : 'text.primary'
                }
              >
                Unlock the true potential of your business with our robust cloud server solutions. 
                Experience seamless scalability, enhanced security, and efficient data management with our state-of-the-art cloud infrastructure. 
                Stay ahead of the competition and ensure maximum uptime while accessing your data from anywhere, at any time.
                <br/>
                <br/>
              </Typography>
            </Box>
            <Box
              component={Card}
              marginTop={{ xs: 2, md: 0 }}
              boxShadow={0}
              borderRadius={2}
            >
              <CardContent sx={{ padding: { xs: 2, sm: 4 } }}>
                <Typography component={'p'}>
                Appliloka's cloud service is a game-changer for our business.
                Their scalable infrastructure and robust security measures give us peace of mind.
                We experienced seamless integration with our existing systems.
                Thanks to Appliloka's Team
                </Typography>
                <Box marginTop={{ xs: 2, sm: 4 }}>
                  <Typography variant={'subtitle1'} sx={{ fontWeight: 700 }}>
                    Sarah
                  </Typography>
                  <Typography color="text.secondary">IT Manager</Typography>
                </Box>
              </CardContent>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container spacing={isMd ? 4 : 2}>
            <Grid
              item
              xs={12}
            >
              <Box
                component={'img'}
                loading="lazy"
                height={1}
                width={1}
                src={cloudserver}
                alt="..."
                borderRadius={2}
                maxWidth={1}
                maxHeight={400}
                sx={{
                  objectFit: 'cover',
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant={'h6'} fontWeight={700} gutterBottom>
                Lifetime guarantee
              </Typography>
              <Typography component={'p'}>
                If your Goby’s performance is anything less than perfect, we’ll
                replace it free of charge.
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant={'h6'} fontWeight={700} gutterBottom>
                Moder design
              </Typography>
              <Typography component={'p'}>
                Righty, lefty, dainty or robust—the soft-touch Goby handle is
                made for every hand.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CaseStudy2;
