/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const WhoWeAre = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box>
      <Grid container spacing={4} direction={isMd ? 'row' : 'column'}>
        <Grid
          item
          container
          alignItems={'center'}
          justifyContent="center"
          xs={12}
          md={6}
        >
          <Box>
            <Typography variant={'h4'} gutterBottom sx={{ fontWeight: 700 }}>
              Who are we?
            </Typography>
            <Typography component={'p'} color={'text.secondary'}>
              Within the walls of Appliloka, a diverse and passionate team thrived. 
              The kingdom was home to brilliant minds from all walks of life, each contributing their unique strengths and perspectives to the grand tapestry of creativity. 
              Our commitment to excellence and collaboration forged an unbreakable bond among us, as we embarked on quests to create solutions that could change the world.
              <br/>
              <br/>
              As the Alphabet A Godzilla stood tall as our guiding beacon, Appliloka soon became known as a hub for bold entrepreneurs, ambitious startups, 
              and established enterprises seeking to turn their aspirations into reality. From crafting immersive mobile apps to architecting futuristic cloud services, 
              every project we undertook was a testament to our passion for delivering unmatched excellence.
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
          md={6}
        >
          <Box>
            <Typography variant={'h4'} gutterBottom sx={{ fontWeight: 700 }}>
              Our Missions
            </Typography>
            <Typography component={'p'} color={'text.secondary'}>
              Yet, our story did not end with success alone. The heart of Appliloka beat with a sense of responsibility towards society and the environment. 
              As guardians of innovation, we embraced sustainable practices, aiming to minimize our carbon footprint and give back to the world that inspired us.
              <br/>
              <br/>
              The tale of Appliloka continued to unfold, and with each chapter, we added new dimensions to our story. 
              As technology evolved, we adapted, embracing the winds of change and staying ahead of the curve.
              <br/>
              <br/>
              But one thing remained constant throughout our journey - the Alphabet A Godzilla roared proudly at the helm, a symbol of the Appliloka spirit - 
              to fearlessly unleash monstrous ideas and create a legacy that transcended generations.
              <br/>
              <br/>
              And so, Appliloka's story continues, a timeless saga of innovation, passion, and transformative creations, 
              leaving an everlasting impact on the world we call home.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WhoWeAre;
