import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FaqGroupItem = ({ title, items }) => {
  const theme = useTheme();
  return (
    <Box>
      <Box marginBottom={2}>
        <Typography fontWeight={700} variant={'h5'}>
          {title}
        </Typography>
      </Box>
      <Box>
        {items.map((item, i) => (
          <Box
            component={Accordion}
            key={i}
            padding={1}
            marginBottom={i === item.length - 1 ? 0 : 2}
            borderRadius={`${theme.spacing(1)} !important`}
            sx={{
              '&::before': {
                display: 'none',
              },
            }}
          >
            <Box
              component={AccordionSummary}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id={`panel1a-header--${i}`}
            >
              <Typography fontWeight={600}>{item.title}</Typography>
            </Box>
            <AccordionDetails>
              <Typography color="text.secondary">{item.subtitle}</Typography>
            </AccordionDetails>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

FaqGroupItem.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
};

const Content = () => {
  return (
    <Box>
      <Box marginBottom={6}>
        <FaqGroupItem
          title={'Custom Moble Apps'}
          items={[
            {
              title: 'What are custom mobile apps?',
              subtitle: 'Custom mobile apps are tailor-made applications developed to meet specific business needs. They are designed and built according to your requirements and can offer unique features, branding, and functionality.'
            },
            {
              title: 'Why should I consider getting a custom mobile app?',
              subtitle: 'Custom mobile apps allow you to have a unique digital presence that aligns perfectly with your business processes. They can enhance customer engagement, improve operational efficiency, and provide a competitive edge.'
            },
            {
              title: 'What platforms do you develop custom mobile apps for?',
              subtitle: 'We develop custom mobile apps for various platforms, including iOS (iPhone and iPad) and Android. This ensures your app reaches a wider audience across different devices.'
            },
            {
              title: 'Can I update or modify the custom app after it\'s developed?',
              subtitle: 'Yes, you can update or modify the custom app based on your evolving business needs. We provide ongoing support and maintenance services to ensure your app remains up-to-date and functional.'
            },
            {
              title: 'How long does it take to develop a custom mobile app?',
              subtitle: 'The development timeline varies depending on the complexity and features of the app. We will provide you with a project timeline and keep you informed about the progress.'
            },
          ]}
        />
      </Box>
      <Box marginBottom={6}>
        <FaqGroupItem
          title={'Cloud Server'}
          items={[
            {
              title: 'What is a Cloud server?',
              subtitle: 'A Cloud server is a virtual server that runs in a cloud computing environment. It offers scalable and on-demand computing resources, such as storage, processing power, and databases, without the need for physical hardware.'
            },
            {
              title: 'What are the benefits of using a Cloud server?',
              subtitle: 'Using a Cloud server provides flexibility, scalability, and cost-efficiency. You can easily scale resources up or down based on your needs, and you only pay for what you use.'
            },
            {
              title: 'Do you offer Cloud server setup and management services?',
              subtitle: 'Yes, we offer Cloud server setup, configuration, and management services. We can help you choose the right cloud provider, set up your server, and ensure its optimal performance.'
            },
            {
              title: 'Can I migrate my existing applications to a Cloud server?',
              subtitle: 'Absolutely. We can assist in migrating your existing applications and data to a Cloud server, ensuring a smooth transition and minimal disruption to your operations.'
            },
            {
              title: 'How secure are Cloud servers?',
              subtitle: 'Cloud servers are designed with robust security measures, including data encryption, access controls, and regular security updates. We prioritize data protection to keep your information safe.'
            },
          ]}
        />
      </Box>
      <Box>
        <FaqGroupItem
          title={'Apps Rental'}
          items={[
            {
              title: 'What is Apps Rental?',
              subtitle: 'Apps Rental is a service where you can rent pre-built applications for a specific period. It allows you to use the app\'s features and functionalities without the upfront cost and commitment of development.'
            },
            {
              title: 'What types of apps are available for rental?',
              subtitle: 'We offer a range of apps for rental across various categories, such as productivity, communication, e-commerce, and more. Check our rental catalog for the current app offerings.'
            },
            {
              title: 'Can I customize a rented app to match my branding?',
              subtitle: 'In most cases, rented apps come with limited customization options. However, we can discuss your specific branding needs and explore the available customization options for the app.'
            },
            {
              title: 'How long can I rent an app?',
              subtitle: 'The rental duration varies based on your needs. You can choose short-term or long-term rentals, and we offer flexible rental plans to accommodate different use cases.'
            },
            {
              title: 'What support is provided during the rental period?',
              subtitle: 'We offer technical support and assistance during the rental period to ensure the smooth functioning of the rented app. If you encounter any issues, our team is here to help.'
            },
          ]}
        />
      </Box>
    </Box>
  );
};

export default Content;
