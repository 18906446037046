import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const mock = [
  {
    title: 'Privacy Policy',
    description:
      'Latest Update 2023',
  },
  {
    title: '1. What information do we collect?',
    description:
      'We may collect personal information such as your name, email address, and contact details when you voluntarily provide them to us through forms or other means. We may also automatically collect certain usage information such as your IP address, browser type, operating system, and interactions with the Website.',
  },
  {
    title: '2. How do we use your information?',
    description:
      'We use the personal information you provide to respond to your inquiries, provide requested services, and send you relevant updates. Usage data is used to analyze and improve the functionality and user experience of the Website.',
  },
  {
    title: '3. Will your information be shared with anyone?',
    description:
      'We may share your personal information with trusted third-party service providers who assist us in operating the Website and providing services to you. We may also disclose your information to comply with legal obligations or protect our rights, privacy, safety, or property.',
  },
  {
    title: '4. Do we use cookies or other tracking technologies?',
    description:
      'We use cookies and similar technologies for various purposes, including authentication, security, preferences, and analytics. You can control cookies through your browser settings.',
  },
  {
    title: '5. Is your information transferred internationally?',
    description:
      'We may transfer your information to third-party service providers located in other countries for processing and storage. We ensure data protection measures are in place for such transfers.',
  },
  {
    title: '6. How long do we keep your information?',
    description:
      'We retain your information for as long as necessary to fulfill the purposes outlined in our Privacy Policy, unless a longer retention period is required by law.',
  },
  {
    title: '7. What are your privacy rights?',
    description:
      'You have the right to access, update, or delete your personal information. You can also object to or restrict the processing of your information. Please refer to our Privacy Policy for more details.',
  },
  {
    title: '8. How can you contact us?',
    description:
      'If you have any questions, concerns, or requests regarding your personal information, please contact us through the contact information provided in our Privacy Policy.',
  },
  {
    title: 'Data Policy',
    description:
      'Latest Update 2023',
  },
  {
    title: '1. Data Collection and Usage',
    description:
      'We collect and process data, including personal information, for the purposes outlined in our Privacy Policy. We use this data to provide and improve our services and ensure the proper functioning of the Website.',
  },
  {
    title: '2. Data Retention',
    description:
      'We retain data for as long as necessary to fulfill the purposes outlined in our Privacy Policy, unless a longer retention period is required by law.',
  },
  {
    title: '3. Data Sharing',
    description:
      'We may share data with third-party service providers for the purposes of hosting, maintenance, and analysis. We ensure that these providers adhere to data protection standards.',
  },
  {
    title: '4. Data Security',
    description:
      'We implement appropriate security measures to protect your data from unauthorized access and disclosure.',
  },
  {
    title: 'Cookies Policy',
    description:
      'Latest Update 2023',
  },
  {
    title: '1. What are Cookies',
    description:
      'Cookies are small pieces of data stored on your device that help improve your browsing experience. They allow us to recognize your device and gather information about your interactions with the Website.',
  },
  {
    title: '2. How We Use Cookies',
    description:
      'We use cookies for various purposes, including authentication, security, preferences, and analytics. You can control cookies through your browser settings.',
  },
  {
    title: '3. Your Choices',
    description:
      'You can control cookies through your browser settings. If you choose to disable cookies, some features of the Website may not function properly.',
  },
  {
    title: '4. Updates to this Policy',
    description:
      'We may update this Cookie Policy to reflect changes in technology or legal requirements. The date at the top of this page indicates when the policy was last revised.',
  },
];

const PrivacySection = ({ title, description }) => {
  return (
    <Box>
      <Typography
        variant={'h6'}
        gutterBottom
        sx={{
          fontWeight: 'medium',
        }}
      >
        {title}
      </Typography>
      <Typography component={'p'} color={'text.secondary'}>
        {description}
      </Typography>
    </Box>
  );
};

PrivacySection.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

const Content = () => {
  return (
    <Box>
      {mock.map((item, i) => (
        <Box key={i} marginBottom={i < mock.length - 1 ? 4 : 0}>
          <PrivacySection {...item} />
        </Box>
      ))}
    </Box>
  );
};

export default Content;
