import React from 'react';

import {
  default as DesktopAppView,
  About as AboutView,
  DesignCompany as DesignCompanyView,
  PortfolioMasonry as PortfolioMasonryView,
  Faq as FaqView,
  ContactPageCover as ContactPageCoverView,
  CompanyTerms as CompanyTermsView,
} from 'views';

const routes = [

  {
    path: '/',
    renderer: (params = {}) => <DesktopAppView {...params} />,
  },
  {
    path: '/about-us',
    renderer: (params = {}) => <AboutView{...params} />,
  },
  {
    path: '/our-services',
    renderer: (params = {}) => <DesignCompanyView{...params} />,
  },
  {
    path: '/our-portfolio',
    renderer: (params = {}) => <PortfolioMasonryView {...params} />,
  },
  {
    path: '/faqs',
    renderer: (params = {}) => <FaqView {...params} />,
  },
  {
    path: '/contact-page-cover',
    renderer: (params = {}) => <ContactPageCoverView{...params} />,
  },
  {
    path: '/company-terms',
    renderer: (params = {}) => <CompanyTermsView{...params} />,
  },
];

export default routes;
