import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import logo from '../../../images/logo.png';

import DirectLinkMenu from './components/DirectLinkMenu';

const SidebarNav = ({ pages }) => {
  const theme = useTheme();
  const { mode } = theme.palette;

  const {
    home = '/',
    aboutUsUrl = '/about-us',
    services = '/our-services',
    ourPortfolio = '/our-portfolio',
    faqs = '/faqs',
  } = pages;

  return (
    <Box>
      <Box width={1} paddingX={2} paddingY={1}>
        <Box
          display={'flex'}
          component="a"
          href="/"
          title="theFront"
          width={{ xs: 100, md: 120 }}
        >
          <Box
            component={'img'}
            src={
              mode === 'light'
                ? logo
                : logo
            }
            height={1}
            width={1}
          />
        </Box>
      </Box>
      <Box paddingX={2} paddingY={2}>
        <Box marginBottom={1}>
          <DirectLinkMenu title={'Home'} url={home} />
        </Box>
        <Box marginBottom={1}>
          <DirectLinkMenu title={'About Us'} url={aboutUsUrl} />
        </Box>
        <Box marginBottom={1}>
          <DirectLinkMenu title={'Services'} url={services} />
        </Box>
        <Box marginBottom={1}>
          <DirectLinkMenu title={'Portfolio'} url={ourPortfolio} />
        </Box>
        <Box marginBottom={1}>
          <DirectLinkMenu title={'FAQS'} url={faqs} />
        </Box>
        <Box marginTop={1}>
          <Button
            variant="contained"
            color="primary"
            component="a"
            target="blank"
            href="/contact-page-cover"
            size="large"
          >
            Contact Us
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

SidebarNav.propTypes = {
  pages: PropTypes.object.isRequired,
};

export default SidebarNav;
