/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const Story = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box>
      <Grid container spacing={4} direction={isMd ? 'row' : 'column'}>
        <Grid item container alignItems={'center'} xs={12} md={6}>
          <Box>
            <Typography variant={'h4'} gutterBottom sx={{ fontWeight: 700 }}>
              Our story
            </Typography>
            <Typography component={'p'}>
              Once upon a time, in a world of boundless imagination, there existed a remarkable company named Appliloka. 
              This tech-driven kingdom was built upon a simple yet powerful philosophy: the Alphabet A Monster.
              <br />
              <br />
              The Alphabet A Monster embodied the fusion of creativity and innovation, symbolizing the ability to create monstrous ideas that would reshape the digital landscape. 
              This mighty creature stood tall, representing the passion and dedication of the Appliloka team to unleash the full potential of technology and turn dreams into reality.
              <br />
              <br />
              At Appliloka, our journey began with a visionary spark and an insatiable curiosity to explore the uncharted realms of the digital universe. 
              From the very inception, we embraced the spirit of adventurous pioneers, fearlessly venturing into unexplored territories to forge new paths in the tech industry.
              <br />
              <br />
              Our slogan, "Monstrous Ideas Unleashed," captured the essence of our purpose. With every project, we sought to harness the power of monstrous ideas, 
              challenging conventional norms and pushing the boundaries of what was thought to be possible. 
              Our solutions were not just limited to mere innovation; they were colossal transformations that left an indelible mark on our clients' lives.
              <br />
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
          md={6}
        >
          <Box maxWidth={500} width={1}>
            <Box
              component={'iframe'}
              title="video"
              width="100%"
              height="100%"
              minHeight={320}
              src="https://www.youtube.com/embed/prQEf0oo8kU"
              frameBorder="0"
              allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
              boxShadow={4}
              borderRadius={2}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Story;
