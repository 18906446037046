import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';

import Main from 'layouts/Main';
import Container from 'components/Container';
import {
  CaseStudy1,
  CaseStudy2,
  CaseStudy3,
  Hero,
  Contact,
} from './components';

const DesignCompany = () => {
  const theme = useTheme();

  return (
    <Main>
      <Container>
        <Hero />
      </Container>
      <Container>
        <CaseStudy1 />
      </Container>
      <Container paddingY={'0 !important'}>
        <CaseStudy2 />
      </Container>
      <Container>
        <CaseStudy3 />
      </Container>
      <Box
        position={'relative'}
        sx={{
          backgroundColor: theme.palette.alternate.main,
        }}
      >
        <Container>
          <Contact />
        </Container>
      </Box>
    </Main>
  );
};

export default DesignCompany;
