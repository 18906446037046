const pages = {
  landings: [],
  company: [],
  account: [],
  secondary: [],
  blog: [],
  portfolio: [],
};

export default pages;
